import styled from '@emotion/styled';
import { Box, ColorKey, Grid, GridProps, Palette } from '@updater/ui-uds';
import { FC, PropsWithChildren } from 'react';

interface TabLayoutTopProps extends GridProps {
  title?: string;
  greeting?: string;
  color?: ColorKey;
}

const BookMoversContainer = styled(Box)`
  background: ${Palette.blue10};
  border-bottom-left-radius: ${({ theme }) => theme.radii.l};
  border-bottom-right-radius: ${({ theme }) => theme.radii.l};
`;

export const TabLayoutTop: FC<PropsWithChildren<TabLayoutTopProps>> = ({
  title,
  greeting,
  children,
  ...gridProps
}) => {
  return (
    <BookMoversContainer>
      <Grid
        style={{ alignItems: 'flex-start' }}
        pt={['l', 'l', 'xxxl', 'xxxl']}
        marginX={['m', null, 'm']}
        pb={['l', null, 'l']}
        {...gridProps}
      >
        {children}
      </Grid>
    </BookMoversContainer>
  );
};
