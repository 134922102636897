import { Flex, Image, Palette, Text, useTheme } from '@updater/ui-uds';
import {
  Envelope,
  FacebookLogo,
  LinkedinLogo,
  TwitterLogo,
} from '@phosphor-icons/react';
import { ComponentProps, FC, useContext } from 'react';
import { BrandingContext } from './BrandingContext';
import { LinkWithIcon } from './LinkWithIcon';

type BrandedProEntityProps = ComponentProps<typeof Flex>;

const applyCircleCropping = (imageUrl: string) =>
  imageUrl.replace('/upload/', '/upload/w_75,h_75,c_thumb,g_face,r_max/');

export const BrandedProEntity: FC<BrandedProEntityProps> = (props) => {
  const { socialBranding, name, teamName, logo, email } =
    useContext(BrandingContext);
  const { twitter, linkedin, facebook } = socialBranding ?? {};
  const theme = useTheme();

  return (
    <Flex flexDirection="column" {...props}>
      <Flex flexDirection="row" justifyContent="space-between" width="100%">
        <Flex flexDirection="column" overflow="hidden">
          <Text variant="mBold">{name}</Text>
          <Text variant="m">{teamName}</Text>

          <Flex flexDirection="column" mt="s" gap={`${theme.space.xxs}px`}>
            {email && (
              <LinkWithIcon
                text={email}
                icon={<Envelope color={Palette.darkBlue} />}
                href={`mailto: ${email}`}
                target="_blank"
              />
            )}

            {facebook && (
              <LinkWithIcon
                icon={<FacebookLogo color={Palette.darkBlue} />}
                text={facebook.replace('https://www.', '')}
                href={facebook}
                target="_blank"
              />
            )}

            {linkedin && (
              <LinkWithIcon
                icon={<LinkedinLogo color={Palette.darkBlue} />}
                text={linkedin.replace('https://www.', '')}
                href={linkedin}
                target="_blank"
              />
            )}

            {twitter && (
              <LinkWithIcon
                text={twitter.replace('https://www.twitter.com/', '@')}
                href={twitter}
                icon={<TwitterLogo color={Palette.darkBlue} />}
                target="_blank"
              />
            )}
          </Flex>
        </Flex>

        {logo?.url && (
          <Image
            width="75px"
            height="auto"
            src={applyCircleCropping(logo.url)}
            alignSelf="flex-start"
          />
        )}
      </Flex>
    </Flex>
  );
};
