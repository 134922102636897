import styled from '@emotion/styled';
import { Box, Flex, Image, Text, TextLink, useTheme } from '@updater/ui-uds';
import { BrandedProModal } from 'components/branded-pro-modal';
import { useBrandedProModalQuery } from 'components/branded-pro-modal/BrandedProModalQuery';
import { useIsHomeOwner } from 'hooks/useIsHomeOwner';
import { useCallback, useContext, useState } from 'react';
import { brandedModalQuery_siteBranding_entityBrandings } from 'types/generated/brandedModalQuery';
import { useTracking } from 'react-tracking';

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px ${({ theme }) => theme.space.m}px;

  ${({ theme }) => theme.mediaQueries.m} {
    flex-direction: row-reverse;
    justify-content: start;
    gap: ${({ theme }) => theme.space.xs}px;
  }
`;

type BrandingParams = {
  branding1: brandedModalQuery_siteBranding_entityBrandings | null;
  branding2: brandedModalQuery_siteBranding_entityBrandings | null;
};

const Names: React.FC<BrandingParams> = ({ branding1, branding2 }) => {
  const { trackEvent } = useTracking({
    domain: 'homeowner_branding_bar',
    object: 'branding_bar',
    verb: 'clicked',
  });

  return (
    <>
      {branding1?.name && (
        <TextLink
          variant="xs"
          showTextDecoration
          onClick={() =>
            trackEvent({
              verb: 'clicked',
              object: 'entity_1',
            })
          }
        >
          {branding1.name}
        </TextLink>
      )}
      {branding2?.name && (
        <>
          <Text variant="xs">and</Text>
          <TextLink
            variant="xs"
            showTextDecoration
            onClick={() =>
              trackEvent({
                verb: 'clicked',
                object: 'entity_2',
              })
            }
          >
            {branding2.name}
          </TextLink>
        </>
      )}
    </>
  );
};

const applyCircleCropping = (imageUrl: string) =>
  imageUrl.replace('/upload/', '/upload/w_75,h_75,c_thumb,g_face,r_max/');

const Photos: React.FC<BrandingParams> = ({ branding1, branding2 }) => {
  const theme = useTheme();

  if (!branding1?.logo?.url && !branding2?.logo?.url) return null;

  return (
    <Flex py="xs" gap={`${theme.space.xs}px`}>
      {branding1?.logo?.url && (
        <Image
          src={applyCircleCropping(branding1.logo.url)}
          width={32}
          height={32}
          borderRadius="50%"
        />
      )}
      {branding2?.logo?.url && (
        <Image
          src={applyCircleCropping(branding2.logo.url)}
          width={32}
          height={32}
          borderRadius="50%"
        />
      )}
    </Flex>
  );
};

export const BrandingProBar: React.FC = () => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const isHomeOwner = useIsHomeOwner();

  const { data, loading } = useBrandedProModalQuery({
    variables: {
      input: {},
    },
  });

  const brandings =
    data?.siteBranding?.entityBrandings?.filter(
      (entityBranding) => entityBranding?.kind === 'agent'
    ) ?? [];

  const [branding1, branding2] = brandings;

  const handleOpenModal = useCallback(
    () => setIsModalOpen(true),
    [setIsModalOpen]
  );

  if (!isHomeOwner) return null;

  if (loading) return null;

  if (!brandings.length) return null;

  return (
    <>
      {/* The modal must be rendered outside of the Container so that clicking in the modal does not bubble up
      and trigger Container.onClick to fire.
      */}
      <BrandedProModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <Container
        bg="secondaryGray"
        onClick={handleOpenModal}
        data-testid="branded-pro-bar"
      >
        <Flex
          display="flex"
          gap={`${theme.space.xxs}px`}
          py="s"
          flexDirection="row"
          flexWrap="wrap"
        >
          <Text variant="xs">Brought to you by</Text>
          <Names branding1={branding1} branding2={branding2} />
        </Flex>
        <Photos branding1={branding1} branding2={branding2} />
      </Container>
    </>
  );
};
