import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Flex,
  Image,
  Text,
  Palette,
  SpinnerFullPage,
} from '@updater/ui-uds';
import { useUserData } from 'flows/core/hooks/use-profile';

import { NativeWebElement } from '@updater/native-web';
import { BrandingProBar } from 'components/branding-pro-bar';

import { TabLayoutCardLayout, TabLayoutContainer } from 'flows/core/components';

import { AppBanner } from 'flows/core/components/app-banner';
import { InitiatePayload } from 'flows/core/types/cards';
import useSWR from 'swr';
import { addDays } from 'date-fns';
import { useTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';
import { ItemDefinition } from 'types';
import { TabLayoutTop } from '../common/tab-layout-top';
import { useIsHomeOwner } from '../../../../../hooks/useIsHomeOwner';
import { useIsMoveUPO } from 'hooks/useIsMoveUPO';
import { useFeature } from '@growthbook/growthbook-react';
import { useIsPropertyValid } from 'hooks/use-is-property-valid';
import { StyledHeaderLink } from 'components/styled-menu-link';
import { Buildings } from '@phosphor-icons/react';
import { css } from '@emotion/react';

interface EssentialLayoutProps {
  toDoCards: InitiatePayload[];
  completedCards: InitiatePayload[];
  loading?: boolean;
  greeting?: string | null;
  brandLogo?: string | undefined;
  propertyName?: string | null | undefined;
}

const PropertyContactPageLink = () => {
  const { value: enabled } = useFeature('consumer_header_property_contact');

  if (enabled) {
    return (
      <StyledHeaderLink
        href="/property-contact"
        data-testid="property-contact-link"
        data-cy="property-contact-link"
      >
        <Flex alignContent="center" flexDirection="row" pb="l">
          <Flex mr="xs" color="cta">
            <Buildings size={24} />
          </Flex>
          Your community info
        </Flex>
      </StyledHeaderLink>
    );
  }

  return null;
};

/* Styled Components */
const BottomSectionOverlap = styled(Box)<{ shouldShowUPOCountdown?: boolean }>`
  ${({ theme, shouldShowUPOCountdown }) => {
    if (!shouldShowUPOCountdown) {
      return css`
        transform: translate3d(0, -112px, 0);

        ${theme.mediaQueries.m} {
          transform: translate3d(0, -112px, 0);
        }
        ${theme.mediaQueries.l} {
          transform: translate3d(0, -104px, 0);
        }
      `;
    }
  }}
`;

const PartnerImage = styled(Image)`
  mix-blend-mode: multiply; // if they upload a jpg with a white background we do this override it
`;

const useTitle = () => {
  const { user, loading } = useUserData();
  const { toAddress } = user?.currentMove || {};
  const title =
    user && `Here is what's required to move to ${toAddress?.street}`;

  return {
    loading,
    title,
  };
};

const UpdaterProLanguage = {
  title: 'Experience Updater Pro with an invite',
  body: 'Updater only gets better when a property partners with us to create a bespoke experience that is tailored to their client’s moving needs.',
};

const EssentialLayout: FC<EssentialLayoutProps> = ({
  toDoCards = [],
  completedCards = [],
  loading = false,
  greeting,
  brandLogo,
  propertyName,
}) => {
  const hasCompletedCards = completedCards && completedCards.length > 0;
  const { title, loading: loadingTitle } = useTitle();
  const isHomeowner = useIsHomeOwner();
  const { on: consumerBrandedHeaderFeatureEnabled } = useFeature(
    'consumer_entity_branded_header'
  );
  const { loading: isMoveUPOLoading, data: { isUPO } = {} } = useIsMoveUPO();
  const { on: showUPOCountdown } = useFeature('show_upo_countdown');
  const shouldShowUPOCountdown = showUPOCountdown && isUPO;

  /* For experiment consumer_co-branded-header_v1 we need to
  be able to get site branding
  */
  const user = useUserData();
  const { recommendedCards, priorityCards } = useMemo(() => {
    if (!isUPO) {
      return { recommendedCards: toDoCards, priorityCards: [] };
    }
    return {
      priorityCards: toDoCards.filter(
        (card) => card.data.required || card.data.priority
      ),
      recommendedCards: toDoCards.filter(
        (card) => !card.data.required && !card.data.priority
      ),
    };
  }, [toDoCards, isUPO]);

  const hasRecommendedCards = recommendedCards && recommendedCards.length > 0;
  const hasPriorityTodoCards =
    priorityCards && priorityCards.length > 0 && isUPO;

  const { data: itemDefinitionsData } = useSWR<ItemDefinition[]>(
    '/v2/item_definitions'
  );

  const { trackEvent } = useTracking<TrackEventInput<unknown>>();

  const showPropertyContact = useIsPropertyValid();

  const updaterPro = () => {
    let isBetween;
    let updaterLite;
    if (user.user?.createdAt) {
      const createdAtDate = new Date(user.user.createdAt);
      const today = new Date(Date.now());
      const firstDate = addDays(createdAtDate, 1);
      const secondDate = addDays(createdAtDate, 2);
      isBetween = !!(today <= secondDate && today >= firstDate);
    }

    if (itemDefinitionsData) {
      updaterLite =
        itemDefinitionsData.filter(({ entity }) => {
          return entity?.code === 'd2c' || entity?.id === 2;
        }).length > 0;
    }

    const shouldShow = isBetween && updaterLite;

    if (shouldShow) {
      trackEvent({
        verb: 'displayed',
        object: 'updater_pro_notice',
        details: {
          language: {
            ...UpdaterProLanguage,
          },
        },
      });
    }

    return shouldShow;
  };

  if (loading || loadingTitle || isMoveUPOLoading) {
    return (
      <Box width="100%">
        <SpinnerFullPage data-testid="essential-layout-loader" />
      </Box>
    );
  }

  return (
    <Flex flexDirection="column" width="100%" className="essential-layout">
      <BrandingProBar />
      {/* @ts-expect-error children issue */}
      <NativeWebElement as={null}>
        <AppBanner propertyName={propertyName ? propertyName : undefined} />
      </NativeWebElement>
      <TabLayoutTop
        gridGap={['xl', 'l', 'm', 'm']}
        gridTemplateColumns={['1fr', '1fr', '.66fr', '.66fr', '.5fr']}
        pb={shouldShowUPOCountdown ? 'none' : 'xxxl'}
      >
        <Box>
          {/* show for non-homeowners or homeowners with the feature flag turned on */}
          {brandLogo &&
            isHomeowner !== 'loading' &&
            !isHomeowner &&
            !consumerBrandedHeaderFeatureEnabled && (
              <Box height={['32px']} width="124px" mb="m">
                <PartnerImage
                  maxHeight="100%"
                  maxWidth="100%"
                  src={brandLogo}
                  alt="brand-logo"
                />
              </Box>
            )}

          {greeting && (
            <Text data-test-id="tab-greeting" variant={['s', 's', 'm']} mb="xs">
              {greeting}
            </Text>
          )}
          <Text
            variant={['xxl', 'xxl', 'xxxl', 'xxxl', 'xxxl']}
            mb={'l'}
            as="h1"
          >
            {title}
          </Text>
          {showPropertyContact ? <PropertyContactPageLink /> : null}
        </Box>
      </TabLayoutTop>
      <BottomSectionOverlap
        //shouldShowUPOCountdown can be undefined and want to send boolean only
        shouldShowUPOCountdown={shouldShowUPOCountdown ?? false}
      >
        <TabLayoutContainer
          marginTop={shouldShowUPOCountdown ? 'none' : undefined}
        >
          {hasPriorityTodoCards && (
            <>
              <Box marginTop="xxxl" />
              <TabLayoutCardLayout
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
                gridGap={['s', 's', 'm', 'm']}
                data={priorityCards}
                section="todo"
                // UPO ONLY
                title={
                  shouldShowUPOCountdown &&
                  priorityCards.length > 0 && (
                    <UPOPriorityHeader tasksLeftToGo={priorityCards.length} />
                  )
                }
              />
            </>
          )}

          {hasRecommendedCards && (
            <>
              <Box marginTop="xxxl" />
              <TabLayoutCardLayout
                title={
                  hasRecommendedCards && isUPO ? (
                    <>
                      Recommended tasks
                      {priorityCards?.length === 0 && (
                        <Text variant="m">
                          Great job - you&apos;ve completed the necessities!
                        </Text>
                      )}
                    </>
                  ) : undefined
                }
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
                gridGap={['s', 's', 'm', 'm']}
                data={recommendedCards}
                section="todo"
              />
            </>
          )}

          {hasCompletedCards && (
            <>
              <Box marginTop="xxxl" />
              <TabLayoutCardLayout
                title={
                  hasPriorityTodoCards || hasRecommendedCards
                    ? 'Completed tasks'
                    : undefined
                }
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
                gridGap={['s', 's', 'm', 'm']}
                data={completedCards}
                section="done"
              />
            </>
          )}

          {updaterPro() && (
            <>
              <Box
                width="100%"
                marginTop="xxxl"
                borderTop="2px dashed #B3B3B3"
                paddingTop="l"
              />

              <Text variant="sBold" color={`${Palette.gray50}`}>
                {UpdaterProLanguage.title}
              </Text>
              <Text marginTop="xs" variant="xs" color={`${Palette.gray70}`}>
                {UpdaterProLanguage.body}{' '}
              </Text>
            </>
          )}
        </TabLayoutContainer>
      </BottomSectionOverlap>
    </Flex>
  );
};

export type UPOPriorityHeaderProps = {
  tasksLeftToGo: number;
};
export const UPOPriorityHeader: React.FC<UPOPriorityHeaderProps> = ({
  tasksLeftToGo,
}) => {
  return (
    <>
      Priority tasks{' '}
      <Text variant="m" as="span">
        {tasksLeftToGo} left to go!
      </Text>
    </>
  );
};

export { EssentialLayout };
