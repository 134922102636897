import React from 'react';

import { Box, SpaceKey, Text as TextUDS } from '@updater/ui-uds';
import styled from '@emotion/styled';

const Content = styled(Box)`
  box-sizing: border-box;
`;

const Text = styled(TextUDS)`
  &:first-letter {
    text-transform: capitalize;
  }
`;

type TabLayoutContainerProps = React.ComponentProps<typeof Content> & {
  title?: string;
  subtitle?: string;
  width?: '100%' | 'auto';
};

export const TabLayoutContainer: React.FC<TabLayoutContainerProps> = ({
  children,
  title,
  subtitle,
  width = 'auto',
  marginTop,
  ...rest
}) => {
  return (
    <Content
      marginX={['m', null, 'm']}
      marginTop={marginTop || [null, null, 'xxxl', 'xxxl']}
      paddingBottom={[
        'calc(6rem + env(safe-area-inset-bottom, 0) * .6)' as SpaceKey,
        'calc(6rem + env(safe-area-inset-bottom, 0) * .6)' as SpaceKey,
        'calc(6rem + env(safe-area-inset-bottom, 0) * .6)' as SpaceKey,
        'm',
        'm',
      ]}
      width={width}
      height="100%"
      {...rest}
    >
      {title && (
        <Text as="h1" variant={['xxl', 'xxl', 'xxxl', 'xxxl']} marginBottom="s">
          {title}
        </Text>
      )}
      {subtitle && (
        <Text variant="s" marginBottom="m">
          {subtitle}
        </Text>
      )}
      {children}
    </Content>
  );
};
