/**
 * This is effectively a rewrite of react-smartbanner with a pared down api
 * and a more idiomatic react implementation
 * if you're curious about what this was based off of, see: https://github.com/patw0929/react-smartbanner/tree/a45823ad085b6e1ee9f0f575fadf4931e7a02be7
 *
 */
import React, { useEffect, useState, useMemo } from 'react';
import * as ua from 'ua-parser-js';
import Cookies from 'js-cookie';
import { differenceInDays } from 'date-fns';
import styled from '@emotion/styled';
import {
  Flex,
  Box,
  Image,
  IconButton,
  Text,
  ButtonLink,
} from '@updater/ui-uds';
import { X } from '@phosphor-icons/react';
import { useTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';
import { useTheme } from '@emotion/react';

interface AppBannerProps {
  daysHidden?: number;
  // daysReminder?: number;
  force?: string;
  propertyName?: string | undefined;
}
type AppMeta = 'apple-itunes-app' | 'google-play-app';

interface AppSettings {
  appMeta: AppMeta;
  appId: string;
  iconRels: string[];
  storeLink: string;
}

const isClient = typeof window !== 'undefined';

const APP_META = {
  ios: 'apple-itunes-app',
  android: 'google-play-app',
} as const;

const IOS_STATIC: AppSettings = {
  appMeta: APP_META.ios,
  appId: '1613789699',
  iconRels: ['apple-touch-icon-precomposed', 'apple-touch-icon'],
  storeLink: `https://apps.apple.com/us/app/id`,
};

const ANDROID_STATIC = {
  appMeta: APP_META.android,
  appId: 'com.updater.consumer',
  iconRels: [
    'android-touch-icon',
    'apple-touch-icon-precomposed',
    'apple-touch-icon',
  ],
  storeLink: 'http://play.google.com/store/apps/details?id=',
};

const useDeviceType = (deviceType: string) => {
  let deviceTypestr = '';
  if (isClient) {
    const agent = ua.UAParser(window.navigator.userAgent);
    if (deviceType) {
      // force set case
      deviceTypestr = deviceType;
    } else if (
      agent.os.name === 'Windows Phone' ||
      agent.os.name === 'Windows Mobile'
    ) {
      deviceTypestr = 'windows';
      // iOS >= 6 has native support for Smart Banner
    } else if (agent.os.name === 'iOS') {
      deviceTypestr = 'ios';
    } else if (
      agent.device.vendor === 'Amazon' ||
      agent.browser.name === 'Silk'
    ) {
      deviceTypestr = 'kindle';
    } else if (agent.os.name === 'Android') {
      deviceTypestr = 'android';
    }
  }
  return deviceTypestr;
};

const useAppSettings = (deviceType: string): AppSettings | undefined => {
  if (deviceType === 'ios') {
    return IOS_STATIC;
  }
  if (deviceType === 'android') {
    return ANDROID_STATIC;
  }
  return undefined;
};

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  line-height: 18px;
`;

const StyledButton = styled(ButtonLink)`
  white-space: nowrap;
  align-self: center;
  margin-right: 10px;
  right: 0px;
`;

const resetLastDismisall = () => {
  Cookies.set('appbanner-last-dismissal', new Date().getTime().toString());
};
const dismissalCount = parseInt(
  Cookies.get('appbanner-dismissal-count') || '0',
  10
);

export const AppBanner: React.FC<AppBannerProps> = ({
  daysHidden = 7,
  force = '',
  propertyName,
}) => {
  const [showing, setShowing] = useState(true);

  const deviceType = useDeviceType(force);
  const settings = useAppSettings(deviceType);
  const appLink = (settings?.storeLink as string) + settings?.appId;
  const theme = useTheme();

  const { trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'essentials_tab',
    pageName: 'essentials_tab',
  });

  const shouldShow = useMemo(() => {
    // If we're on the server (!isClient)
    // Or we're not on a phone (!deviceType)
    // Or we don't know where to link the user (!settings?.appId)
    // Then we don't want to show regardless
    if (!isClient || !deviceType || !settings?.appId) {
      return false;
    }

    const lastDismissalCookie = Cookies.get('appbanner-last-dismissal');

    // First time viewing the banner(or never dismissed) so we should show it
    if (!lastDismissalCookie) {
      return true;
    }

    const lastDismissal = Date.parse(lastDismissalCookie);

    const diffInDays = differenceInDays(new Date(), lastDismissal);
    // It's been X days since they dismissed and they've only dismissed once before show it
    if (diffInDays >= daysHidden && dismissalCount < 2) {
      return true;
    }

    // It's not yet been x days, or they've dismissed more than once
    return false;
  }, [daysHidden, settings?.appId, deviceType]);

  useEffect(() => {
    if (shouldShow && showing) {
      trackEvent({
        object: 'native_app_promotion',
        verb: 'viewed',
      });
    }
  }, [trackEvent, shouldShow, showing]);

  /**
   * hide the banner
   * incremenet the dismissal count
   * completely hide banner if dismissal count >= 2
   * set the last dismissal date
   */
  const close = () => {
    if (isClient) {
      setShowing(false);
    }
    if (Number.isNaN(dismissalCount)) {
      Cookies.set('appbanner-dismissal-count', '1');
    } else {
      Cookies.set('appbanner-dismissal-count', (dismissalCount + 1).toString());
    }
    resetLastDismisall();
    trackEvent({
      domain: 'native_app_promotion',
      object: 'dismiss_banner',
      verb: 'clicked',
    });
  };

  // in the future we may want to handle the intent to install differently
  const install = () => {
    trackEvent({
      domain: 'native_app_promotion',
      object: 'install_link',
      verb: 'clicked',
    });
  };

  if (!shouldShow || !showing) return null;

  return (
    <Box
      className="appbanner-container"
      backgroundColor="primary"
      data-testid="appbanner"
    >
      <Flex flexDirection="row" paddingY="s" alignItems="center">
        <IconButton
          data-testid="icon-button"
          icon={<X size={25} color={theme.colors.white} />}
          onClick={close}
          size="s"
          variant="transparent"
          marginLeft="xxs"
        />
        <Image
          src="/images/app-logo-light.png"
          alt="download the app"
          width="55"
          height="55"
          marginLeft="s"
        />

        <Text as="span" variant="s" paddingX="s" color="white">
          {propertyName ? (
            <StyledText variant="sBold">
              Moving to the {propertyName} is easier on the app
            </StyledText>
          ) : (
            <StyledText variant="sBold">
              Moving to your new community is easier on the app
            </StyledText>
          )}
        </Text>

        <StyledButton
          variant="primary"
          size="s"
          href={appLink}
          onClick={install}
          dataCy="appbannerlink"
          data-testid="appbannerlink"
        >
          <Text variant="xxsBold" color={theme.colors.white}>
            Get it now
          </Text>
        </StyledButton>
      </Flex>
    </Box>
  );
};
