import styled from '@emotion/styled';
import { Box, Flex, TextLink, useTheme } from '@updater/ui-uds';
import Link from 'next/link';
import { ComponentProps, FC, ReactElement } from 'react';

type LinkWithIconProps = {
  icon: ReactElement;
  text: string;
  href: string;
} & Pick<ComponentProps<typeof TextLink>, 'target'>;

const TextLinkEllipsis = styled(TextLink)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LinkWithIcon: FC<LinkWithIconProps> = (props) => {
  const { icon, text, href, target } = props;
  const textColor = useTheme()?.colors?.text;

  return (
    <Flex>
      <Box mr="xs">{icon}</Box>
      <Link data-testid="link-with-icon" href={href} passHref>
        <TextLinkEllipsis
          data-testid="link-text-ellipsis"
          color={textColor}
          variant="s"
          target={target}
          style={{}}
        >
          {text}
        </TextLinkEllipsis>
      </Link>
    </Flex>
  );
};
