import { useUserAndInviteInfo } from './useUserAndInviteInfo';

export function useResidentOnboardingEnabledQuery() {
  const query = useUserAndInviteInfo();

  return {
    ...query,
    data: query.loading
      ? undefined
      : {
          residentOnboardingEnabled:
            query.data?.inviteByToken?.invite?.residentOnboardingEnabled ??
            query.data?.getUser?.user.currentMove?.residentOnboardingEnabled,
        },
  };
}
