export const DOLLY_SUPPORT_NUMBER = ` 1-844-571-1131`;

export const MOVE_FLOW_DOMAINS = {
  MOVING_TAB: 'moving_tab',
  /**
   * MYS = "Move Your Stuff" & Dolly.
   */
  MYS: 'mys',
  /**
   * MS = "Move Services".
   */
  MS_BOXES: 'ms_boxes',
};
