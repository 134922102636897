import { Box, Flex, SpinnerFullPage, Text } from '@updater/ui-uds';
import { FC } from 'react';

import { BrandingProBar } from 'components/branding-pro-bar';

import { TabLayoutCardLayout, TabLayoutContainer } from 'flows/core/components';
import { BookMovers } from 'flows/core/components/book-movers';
import { InitiatePayload, PromoPayload } from 'flows/core/types/cards';
import { getUserInfo_getUser_user_currentMove } from 'types/generated/getUserInfo';
import { SingleCard } from '../../cards/single-card';
import { TabLayoutTop } from '../common/tab-layout-top';
import { QuestionsTextWithLinkOut } from 'flows/moving/components/QuestionsTextWithLinkOut';

interface MovingLayoutProps {
  initiateCards: InitiatePayload[];
  draftCards: PromoPayload[];
  loading?: boolean;
  currentMove: getUserInfo_getUser_user_currentMove;
}

const MovingLayout: FC<MovingLayoutProps> = ({
  initiateCards = [],
  draftCards = [],
  loading = false,
  currentMove,
}) => {
  if (loading) {
    return (
      <Box width="100%">
        <SpinnerFullPage />
      </Box>
    );
  }

  return (
    <Flex flexDirection="column" width="100%" className="moving-layout">
      <BrandingProBar />
      <TabLayoutTop
        gridGap={['xl', 'l', 'm', 'm']}
        gridTemplateColumns={['1fr', '1fr', '1fr', 'repeat(2, 1fr)']}
        pb="xxxxl"
      >
        <Box
          pb="xl"
          // @ts-expect-error: the theme type needs to be fixed
          pt={['l', 'l', 0, 0]}
        >
          <Text variant={['xxl', 'xxl', 'xxl', 'xxxl', 'xxxl']} mb="xs">
            Book movers
          </Text>
          <Text variant={['m', 'm', 'm']}>
            Move a couch or your whole home.
          </Text>
          <Box mt={['m', 'm', 'l', 'l']}>
            <BookMovers currentMove={currentMove} />
          </Box>
          <QuestionsTextWithLinkOut py="m" />
        </Box>
      </TabLayoutTop>
      <Box pt="m">
        <TabLayoutContainer width="auto">
          {draftCards.length > 0 && (
            <Box mb="s">
              <TabLayoutCardLayout
                title="Drafts"
                gridTemplateColumns={[
                  '1fr',
                  '1fr',
                  'repeat(2, 1fr)',
                  'repeat(3, 1fr)',
                ]}
                data={draftCards}
              />
            </Box>
          )}
          <Box mt="xxxl">
            {(initiateCards || []).map((card, index) => (
              <SingleCard
                card={card}
                key={card.identifier}
                position={draftCards.length + index}
              />
            ))}
          </Box>
        </TabLayoutContainer>
      </Box>
    </Flex>
  );
};

export { MovingLayout };
