import { ApolloError } from '@apollo/client';
import { dollyEligibility } from 'types/generated/dollyEligibility';

export type ErrorType = 'singleLocation' | 'multipleLocation';

export const ErrorTypeMap: { [key in ErrorType] } = {
  singleLocation: 'singleLocation',
  multipleLocation: 'multipleLocation',
};

export type ErrorDetails = {
  dollyServiceError?: string | null;
  fromAddressEligible?: boolean | null;
  toAddressEligible?: boolean | null;
  fromDollyServiceError?: string;
  toDollyServiceError?: string;
};

// used for tracking
const getErrorDetails = (locationIndex: Number | null, data) => {
  if (locationIndex === 0) {
    return {
      fromAddressEligible:
        data?.dollyEligibility?.addressEligibility[0]?.eligible,
      fromDollyServiceError:
        data?.dollyEligibility?.addressEligibility[0]?.denialReason[0]
          .serviceReason,
    };
  }

  if (locationIndex === 1) {
    return {
      toAddressEligible:
        data?.dollyEligibility?.addressEligibility[1]?.eligible,
      toDollyServiceError:
        data?.dollyEligibility?.addressEligibility[1]?.denialReason[0]
          .serviceReason,
    };
  }

  return {
    dollyServiceError: data?.dollyEligibility?.denialReason[0].serviceReason,
    fromAddressEligible:
      data?.dollyEligibility?.addressEligibility[0]?.eligible,
    fromDollyServiceError:
      data?.dollyEligibility?.addressEligibility[0]?.denialReason[0]
        ?.serviceReason,
    toAddressEligible: data?.dollyEligibility?.addressEligibility[1]?.eligible,
    toDollyServiceError:
      data?.dollyEligibility?.addressEligibility[1]?.denialReason[0]
        ?.serviceReason,
  };
};

export interface DetermineDollyEligibilityErrorsProps {
  numAddresses: Number;
  fromAddress: string;
  toAddress: string;
  data?: dollyEligibility | null;
  error?: ApolloError | string;
}
const singleAddressError =
  'Sorry! This location is outside of our service area.';
const longDistanceError = [
  'SERVICE_AVAILABILITY_REASON_LOCATIONS_UNAVAILABLE_NOT_SAME_SERVICE_AREA',
  'SERVICE_AVAILABILITY_REASON_LOCATIONS_UNAVAILABLE_OVER_LONG_RANGE_DELIVERY_LIMIT',
];

const fromAddressError = {
  locationIndex: 0,
  errorMsg: singleAddressError,
  errorType: ErrorTypeMap.singleLocation,
};
const toAddressError = {
  locationIndex: 1,
  errorMsg: singleAddressError,
  errorType: ErrorTypeMap.singleLocation,
};

export const determineDollyEligibilityErrors = ({
  numAddresses,
  fromAddress,
  toAddress,
  data = null,
}: DetermineDollyEligibilityErrorsProps): {
  locationIndex: Number;
  errorMsg: String;
  errorType: ErrorType | null;
  errorDetails: ErrorDetails;
} => {
  if (
    data?.dollyEligibility?.denialReason.length > 0 &&
    (data?.dollyEligibility.denialReason[0].serviceReason ===
      longDistanceError[0] ||
      data?.dollyEligibility.denialReason[0].serviceReason ===
        longDistanceError[1])
  ) {
    const errorDetails = getErrorDetails(null, data);
    const longDistanceErrorMsg =
      data?.dollyEligibility.denialReason[0].serviceReason ===
      longDistanceError[0]
        ? longDistanceError[0]
        : longDistanceError[1];

    return {
      locationIndex: null,
      errorMsg: longDistanceErrorMsg,
      errorType: ErrorTypeMap.multipleLocation,
      errorDetails,
    };
  }

  if (
    numAddresses === 1 &&
    data?.dollyEligibility?.addressEligibility[0]?.eligible === false
  ) {
    if (fromAddress && !toAddress) {
      const errorDetails = getErrorDetails(0, data);
      return {
        ...fromAddressError,
        errorDetails,
      };
    }
    const errorDetails = getErrorDetails(1, data);
    return {
      ...toAddressError,
      errorDetails,
    };
  }

  if (numAddresses === 2) {
    if (
      data?.dollyEligibility?.addressEligibility[0]?.eligible === false &&
      data?.dollyEligibility?.addressEligibility[1]?.eligible === false
    ) {
      const errorDetails = getErrorDetails(null, data);
      return {
        locationIndex: numAddresses,
        errorMsg: singleAddressError,
        errorType: ErrorTypeMap.singleLocation,
        errorDetails,
      };
    }

    if (
      !data?.dollyEligibility?.addressEligibility[0]?.eligible &&
      data?.dollyEligibility?.addressEligibility[1]?.eligible
    ) {
      const errorDetails = getErrorDetails(0, data);
      return {
        ...fromAddressError,
        errorDetails,
      };
    }
    if (
      data?.dollyEligibility?.addressEligibility[0]?.eligible &&
      !data?.dollyEligibility?.addressEligibility[1]?.eligible
    ) {
      const errorDetails = getErrorDetails(1, data);
      return {
        ...toAddressError,
        errorDetails,
      };
    }

    if (data?.dollyEligibility?.eligible === false) {
      const errorDetails = getErrorDetails(null, data);
      return {
        locationIndex: numAddresses,
        errorMsg: singleAddressError,
        errorType: ErrorTypeMap.singleLocation,
        errorDetails,
      };
    }
  }

  return {
    locationIndex: null,
    errorMsg: null,
    errorType: null,
    errorDetails: null,
  };
};
