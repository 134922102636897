import { ReactNode } from 'react';
import { AddressHomeSize } from 'types/generated/globalTypes';

export interface Address {
  street?: string;
  unit?: string;
  city?: string;
  postalCode?: string;
  state?: string;
}

export interface DollyFlowOption {
  title: string;
  subtitle: string;
  value: string;
  icon?: ReactNode;
}

export enum DollyUseCase {
  Whole = 'whole',
  JustBigStuff = 'just_big_stuff',
}

export enum DollySubUseCase {
  HelpersTruck = 'helpers_truck',
  HelpersOnly = 'helpers_only',
}

export enum DollyRoute {
  Landing = '/moving',
  Quantity = '/move-your-stuff/quantity',
  HelpNeeded = '/move-your-stuff/help-needed',
}

export interface DollyMetadata extends Record<string, any> {
  from_address: string | null;
  from_home_size: AddressHomeSize | null;
  to_address: string | null;
  to_home_size: AddressHomeSize | null;
}

export interface DollyItem extends Record<string, any> {
  id: number | string;
  metadata: DollyMetadata | null;
}
