import styled from '@emotion/styled';
import {
  Box,
  Button as ButtonUDS,
  Grid,
  Image,
  Text,
  Flex,
  SpinnerFullPage,
} from '@updater/ui-uds';
import { BrandingProBar } from 'components/branding-pro-bar';
import {
  SingleCard,
  TabLayoutCardLayout,
  TabLayoutContainer,
} from 'flows/core/components';
import {
  CardData,
  CardDataWithHeroExtras,
  InternalCardTarget,
  PromoPayload,
} from 'flows/core/types/cards';
import { useRouter } from 'next/router';
import React from 'react';
import { LocalRecommendations } from './LocalRecommendations';

const SMALL_SCREEN_GRID_MAX_WIDTH = '480px';
const BIG_SCREEN_HERO_CARD_WIDTH = '367px';

type TodayLayoutProps = {
  cards: PromoPayload[];
  promotedCard?: CardDataWithHeroExtras;
  loading: boolean;
  // brandLogo?: string | null;
};

type BackgroundImageHeroCardProps = {
  transform: string;
};

const TopSectionGrid = styled(Grid)`
  .button {
    display: none;
    ${({ theme }) => theme.mediaQueries.m} {
      display: block;
    }
  }
`;

const Button = styled(ButtonUDS)`
  width: 100%;
  max-width: 480px;

  ${({ theme }) => theme.mediaQueries.m} {
    width: 236px;
    max-width: auto;
  }
  ${({ theme }) => theme.mediaQueries.l} {
    width: ${BIG_SCREEN_HERO_CARD_WIDTH};
    max-width: 67%;
  }
`;

const BottomButton = styled(Button)`
  ${({ theme }) => theme.mediaQueries.l} {
    width: auto;
    max-width: unset;
  }
`;

const BoxHeroCard = styled(Box)`
  max-width: ${SMALL_SCREEN_GRID_MAX_WIDTH};
  ${({ theme }) => theme.mediaQueries.l} {
    margin: auto;
    width: ${BIG_SCREEN_HERO_CARD_WIDTH};
  }
`;

const BackgroundImageHeroCard = styled(Image)<BackgroundImageHeroCardProps>`
  position: absolute;
  height: 410px;
  transform: ${({ transform }) => transform};
  display: none;
  ${({ theme }) => theme.mediaQueries.l} {
    display: block;
  }
`;

const BackgroundImageHeroCardMobile = styled(
  Image
)<BackgroundImageHeroCardProps>`
  display: block;
  ${({ theme }) => theme.mediaQueries.l} {
    display: none;
  }
  position: absolute;
  height: 382px;
  transform: ${({ transform }) => transform};
`;

export const TodayLayout: React.FC<TodayLayoutProps> = ({
  // greeting,
  cards = [],
  promotedCard,
  loading = true,
  // brandLogo,
}) => {
  const { heroExtras, ...heroCard } = promotedCard || {};

  const router = useRouter();
  if (loading) {
    return (
      <Box width="100%">
        <SpinnerFullPage />
      </Box>
    );
  }

  const routeWithCTA = () => {
    const {
      data: { action },
    } = (heroCard as CardDataWithHeroExtras) || {};

    router.push((action as InternalCardTarget).route);
  };

  return (
    <Flex flexDirection="column">
      <BrandingProBar />

      <TabLayoutContainer>
        <TopSectionGrid
          gridGap={['xl', 'l', 'm', 'm']}
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          style={{ alignItems: 'center' }}
        >
          <Box
            top={[null, null, null, '-24px']}
            position="relative"
            pt="3.5rem"
          >
            {/* commenting out below until launch */}
            {/* {brandLogo && (
                <Box height="56px" width="160px" mb="s">
                  <Image
                    height="100%"
                    width="100%"
                    src={brandLogo}
                    alt="brand-logo"
                  />
                </Box>
              )}
              */}
            {heroExtras?.heading && (
              <Text
                variant={['xxl', 'xxl', 'xxxl', 'xxxl', 'xxxl']}
                mb="l"
                as="h1"
              >
                {heroExtras.heading}
              </Text>
            )}
            {heroExtras?.ctaText && (
              <Button
                className="button"
                variant="primary"
                size="l"
                onClick={routeWithCTA}
              >
                {heroExtras.ctaText}
              </Button>
            )}
          </Box>
          {heroCard && (
            <BoxHeroCard position="relative">
              <BackgroundImageHeroCard
                zIndex="-1"
                transform="rotate(8.64deg) translate(34px, -11px)"
                width={[
                  SMALL_SCREEN_GRID_MAX_WIDTH,
                  SMALL_SCREEN_GRID_MAX_WIDTH,
                  SMALL_SCREEN_GRID_MAX_WIDTH,
                  '100%',
                ]}
                src="/images/today-back-desktop.svg"
                alt="bg1"
              />
              <BackgroundImageHeroCardMobile
                zIndex="-2"
                transform="rotate(7deg) translate(20px, -17px)"
                width={[
                  SMALL_SCREEN_GRID_MAX_WIDTH,
                  SMALL_SCREEN_GRID_MAX_WIDTH,
                  SMALL_SCREEN_GRID_MAX_WIDTH,
                  '100%',
                ]}
                src="/images/today-back-mobile.svg"
                alt="bg2"
              />
              <SingleCard card={heroCard as CardData} position="hero" />
            </BoxHeroCard>
          )}
        </TopSectionGrid>
        <Box mt={['5rem', '5rem', 'xxxxl', 'xxxxl']}>
          {cards && (
            <>
              <TabLayoutCardLayout
                title="Next on your list"
                gridTemplateColumns={[
                  '1fr',
                  'minmax(auto, 480px)',
                  'repeat(2, 1fr)',
                  'repeat(3, 1fr)',
                ]}
                data={cards}
              />

              <Grid gridGap={['xl']}>
                <Grid
                  gridTemplateColumns={[
                    '1fr',
                    'minmax(auto, 480px)',
                    'repeat(2, 1fr)',
                    'repeat(3, 1fr)',
                  ]}
                  gridGap={[null, null, null, '24px']}
                >
                  <BottomButton
                    variant="secondary"
                    size="l"
                    mt="xl"
                    onClick={() => {
                      router.push('/');
                    }}
                  >
                    View all to dos
                  </BottomButton>
                </Grid>

                <LocalRecommendations />
              </Grid>
            </>
          )}
        </Box>
      </TabLayoutContainer>
    </Flex>
  );
};
