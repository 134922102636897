import { useSiteBranding } from 'flows/resident-onboarding/hooks/use-site-branding';
import { usePreferredProvidersQuery } from 'utils/use-preferred-providers-query';
import { useMemo } from 'react';

const companyKinds = ['brokerage', 'property', 'property_management'];

/**
 * Return the recommended by value for this users preferred providers.
 * @returns string name of agent or company
 */
export function useRecommendedBy(): string {
  const { data: preferredProviders } = usePreferredProvidersQuery();
  const { data: siteBranding } = useSiteBranding();

  const company = useMemo(() => {
    if (siteBranding) {
      return siteBranding.siteBranding?.entityBrandings?.find(
        (entity) => entity?.kind && companyKinds.includes(entity?.kind)
      );
    }
    return null;
  }, [siteBranding]);

  const agent = useMemo(() => {
    if (siteBranding) {
      return siteBranding.siteBranding?.entityBrandings?.find(
        (entity) => entity?.kind === 'agent'
      );
    }
    return null;
  }, [siteBranding]);

  const hasAgentRecommendation = useMemo(() => {
    if (preferredProviders?.preferredProviders) {
      const providers = preferredProviders.preferredProviders;
      return providers.preferredProviders.reduce((hasAgent, provider) => {
        if (provider.ownerKind === 'agent') {
          return true;
        }
        return hasAgent;
      }, false);
    }
    return null;
  }, [preferredProviders]);

  return useMemo<string>(() => {
    let recommendedBy = '';
    if (hasAgentRecommendation && agent?.name) {
      recommendedBy = agent.name;
    }
    if (company?.name && !recommendedBy) {
      recommendedBy = company.name;
    }
    return recommendedBy;
  }, [agent, company, hasAgentRecommendation]);
}
