import { AddressHomeSize, AddressInput } from 'types/generated/globalTypes';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { DollyItem, DollySubUseCase, DollyUseCase } from '../types/types';

interface DollyFlowStore {
  fromAddress?: string;
  setFromAddress: (fromAddress?: string) => void;

  fromHomeSize?: AddressHomeSize;
  setFromHomeSize: (fromHomeSize?: AddressHomeSize) => void;

  toAddress?: string;
  setToAddress: (toAddress?: string) => void;

  toHomeSize?: AddressHomeSize;
  setToHomeSize: (toHomeSize?: AddressHomeSize) => void;

  useCase?: DollyUseCase;
  setUseCase: (useCase?: DollyUseCase) => void;

  subUseCase?: DollySubUseCase;
  setSubUseCase: (subUseCase?: DollySubUseCase) => void;

  itemId?: number | string;
  setItemId: (itemId?: number | string) => void;

  mapItemToState: (item: DollyItem) => void;

  localFromAddress?: AddressInput;
  setLocalFromAddress: (fromAddress: AddressInput) => void;

  localToAddress?: AddressInput;
  setLocalToAddress: (toAddress: AddressInput) => void;

  reset: () => void;
}

const EMPTY_STATE = {
  fromAddress: undefined,
  fromHomeSize: undefined,
  toAddress: undefined,
  toHomeSize: undefined,
  useCase: undefined,
  subUseCase: undefined,
  itemId: undefined,
};

export const useDollyFlowStore = create<DollyFlowStore>(
  persist<DollyFlowStore>(
    (set) => ({
      setFromAddress: (fromAddress?: string) => set({ fromAddress }),

      setFromHomeSize: (fromHomeSize?: AddressHomeSize) =>
        set({ fromHomeSize }),

      setToAddress: (toAddress?: string) => set({ toAddress }),

      setToHomeSize: (toHomeSize?: AddressHomeSize) => set({ toHomeSize }),

      setUseCase: (useCase?: DollyUseCase) => set({ useCase }),

      setSubUseCase: (subUseCase?: DollySubUseCase) => set({ subUseCase }),

      setItemId: (itemId?: number | string) => set({ itemId }),

      setLocalFromAddress: (localFromAddress: AddressInput) =>
        set({ localFromAddress }),

      setLocalToAddress: (localToAddress: AddressInput) =>
        set({ localToAddress }),

      // Takes metadata from the `/v2/items` API and maps it back into local
      // state.
      mapItemToState: (item: DollyItem) => {
        if (!item.metadata) {
          return set({ ...EMPTY_STATE });
        }

        return set({
          fromAddress: item.metadata?.from_address ?? undefined,
          fromHomeSize: item.metadata?.from_home_size ?? undefined,
          toAddress: item.metadata?.to_address ?? undefined,
          toHomeSize: item.metadata?.to_home_size ?? undefined,
          itemId: item.id,
        });
      },

      reset: () => set({ ...EMPTY_STATE }),
    }),
    {
      name: 'DOLLY_FLOW_STORE',
      getStorage: () => sessionStorage,
    }
  )
);

// Selector for easily getting the number of submitted addresses.
export const selectNumberOfAddresses = (state: DollyFlowStore) =>
  Number(!!state.fromAddress) + Number(!!state.toAddress);
