import {
  Flex,
  Palette,
  Box,
  Text,
  TextLink,
  useViewportSizes,
} from '@updater/ui-uds';
import { ComponentProps, FC, useCallback, useContext, useMemo } from 'react';
import Link from 'next/link';
import { MapPinLine } from '@phosphor-icons/react';
import { useTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';
import { useRecommendedBy } from 'hooks';
import { usePreferredProvidersQuery } from 'utils/use-preferred-providers-query';

export const LocalRecommendations: FC<Partial<ComponentProps<typeof Flex>>> = (
  props
) => {
  const { isMobile } = useViewportSizes();

  const { data, loading } = usePreferredProvidersQuery({});
  const recommendedBy = useRecommendedBy();

  const { Track, trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'today_tab',
    pageName: 'today_tab',
  });

  const track = useCallback(
    () =>
      trackEvent({
        object: 'local-guide-entry-card',
        verb: 'clicked',
      }),
    [trackEvent]
  );

  const hasNoRecommendations = useMemo(() => {
    const preferredProviders =
      data?.preferredProviders?.preferredProviders || [];
    return preferredProviders.length === 0;
  }, [data]);

  if (loading || hasNoRecommendations) {
    return null;
  }

  return (
    <Track>
      <Link href="/recommended-providers" passHref>
        <Flex
          as={TextLink}
          // @ts-ignore
          backgroundColor={Palette.mint20}
          borderRadius="xs"
          pl="m"
          pr="s"
          pt="s"
          pb="s"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          onClick={track}
          {...props}
        >
          <Text variant={isMobile ? 'm' : 'l'}>
            Local recommendations from {recommendedBy}
          </Text>

          <Box p="s" borderRadius="s" backgroundColor="white" ml="s">
            <MapPinLine weight="fill" color={Palette.mint} size={32} />
          </Box>
        </Flex>
      </Link>
    </Track>
  );
};
